import React, { Suspense } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
// import TheContent from "../src/components/TheContent";
import Loading from "./components/ModalLoading";
const TheContent = React.lazy(() => import("./components/TheContent"));

let persistor = persistStore(store);

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <TheContent />
        </PersistGate>
      </Provider>
    </Suspense>
  );
}

export default App;
