import { createSlice } from "@reduxjs/toolkit";
export const gameSlice = createSlice({
  name: "game",
  initialState: {
    gameProvider: [],
  },
  reducers: {
    setGameProvider: (state, action) => {
      state.gameProvider = action.payload;
    },
  },
});

export const { setGameProvider } = gameSlice.actions;
export const getGameProvider = (state) => state.game.gameProvider;

export default gameSlice.reducer;
