import React from "react";
import { Grid, Backdrop, CircularProgress } from "@mui/material";
import logo from "../assets/logo/logo-default.png";

export default function ModalLoading({ isOpen, imglogo }) {
  return (
    <div>
      {imglogo ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isOpen === undefined ? false : isOpen}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12} align="center">
              <img
                src={imglogo ? imglogo : logo}
                width="200px"
                alt="icon-profile"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12} align="center">
              <h4 style={{ color: "#fcfcfc" }}>กำลังโหลด....</h4>
            </Grid>
          </Grid>
        </Backdrop>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isOpen === undefined ? false : isOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
}
