import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import memberReducer from "./slices/member";
import systemReducer from "./slices/system";
import promotionReducer from "./slices/promotion";
import rewardReducer from "./slices/reward";
import gameReducer from "./slices/game";
import dataHuayReducer from "./slices/datahuay";

const reducers = combineReducers({
  member: memberReducer,
  system: systemReducer,
  promotion: promotionReducer,
  reward: rewardReducer,
  game: gameReducer,
  datahuay: dataHuayReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: window.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
